var postlist;

const getData = () => {
    const skeleton = document.getElementById("skeleton");
    let url = `${process.env.APIURL}/blog/api/posts`;
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            if (skeleton) skeleton.remove();
            const blogBox = document.getElementById("blogBox");
            if (blogBox) {
                data.data.slice(0, 4).forEach((ele) => {
                    const node = document.createElement("div");
                    const utcDate = ele.created_at;
                    const date = new Date(utcDate);
                    node.classList.add("swiper-slide");
                    let content = `<div class="mb-2 scale-100 hover:scale-105 transition-all duration-500 hover:shadow-md ">
                        <div class="mb-2">
                            <a href=${ele.url} target="_blank">
                                <div class="slider_bg" style="background: url(${ele.dp_url});"></div>
                            </a>
                        </div>
                        <div class="p-2">
                            <div class="flex gap-4 items-center mb-2">
                                <div class="border-r pr-4 flex items-center">
                                    <a href=${ele.url} class="Gilroy-Medium text-sm text-violet-900 capitalize">${ele.category}</a>
                                </div>
                                <div>
                                    <a href=${ele.url} target="_blank" class="flex gap-2 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5C3EB3" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <p class="Gilroy-Medium text-sm text-violet-900">${ele.reading_time}</p>
                                    </a>
                                </div>
                            </div>
                            <p class="mb-2 Gilroy-Bold heading_4">${ele.title.length > 30 ? ele.title.slice(0, 30) + "..." : ele.title}</p>
                            <p class="mb-2 Gilroy-Regular">${ele.description.length > 100 ? ele.description.slice(0, 100) + "..." : ele.description}</p>
                            <div class="flex justify-between items-center">
                                <div>
                                    <a href=${ele.url} target="_blank" class="flex gap-2 items-center">
                                        <img src=${ele.author.dp_url} alt="${ele.title.slice(0, 50)}" class="blogsImage w-10 h-10 rounded-full border border-slate-300 object-fill"/>
                                        <p class="Gilroy-Medium text-sm">${ele.author.name}</p>
                                    </a>
                                </div>
                                <div>
                                    <p class="Gilroy-Medium text-sm">${date.toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    node.innerHTML = content;
                    blogBox.appendChild(node);
                });
            }
        })
        .catch((err) => console.log(err));
};
getData();

let emptyArr = [];
let limit = 3;
let offset = 0;

const career = (lim, off) => {
    let url = `${process.env.APIURL}/blog/api/open-positions?limit=${lim}&offset=${off}`;
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            offset += 3;
            emptyArr.push(...data?.data?.results || []);
            const positionBox = document.getElementById("position_box");
            if (positionBox) {
                data?.data?.results.forEach((ele) => {
                    postlist = data.data;
                    const node = document.createElement("div");
                    node.style.cursor = "pointer";
                    node.classList.add("bg-violet");
                    let CRdate = new Date(ele.updated_at);
                    CRdate = new Intl.DateTimeFormat("en-US").format(CRdate);
                    let content = `<div class="col-span-1 w-full py-8 lg:px-14 px-4 border-b transition duration-150 ease-out hover:ease-in relative all_vaccancy" id="${ele.id}">
                        <div class="flex items-center justify-between">
                            <div>
                                <p class="xl:text-base text-sm Gilroy-semiBold opacity-80">${CRdate}</p>
                                <h3 class="xl:text-2xl text-lg Gilroy-semiBold capitalize text-white">${ele.title}</h3>
                                <p class="mt-4 lg:mt-0">
                                    <span class="text-base Gilroy-semiBold opacity-80">
                                        ${ele.max_experience !== "None" ? `<span>${ele.min_experience} - ${ele.max_experience} </span> Year Experience` : `<span>${ele.min_experience} + </span> Year Experience`}
                                    </span>
                                </p>
                            </div>
                            <div class="text-base capitalize Gilroy-Medium border-white border-1 border px-5 py-2">view detail</div>
                        </div>
                    </div>`;
                    node.innerHTML = content;
                    positionBox.appendChild(node);
                    initializeEvent(ele.id);
                });
            }


            document.getElementById('apply_career').classList.remove('block')
            document.getElementById('apply_career').classList.add('hidden')

            if (emptyArr.length === data?.data?.count) {
                const showBtn = document.querySelector('#show_btn button');
                if (showBtn) showBtn.classList.add('hidden');
            }
        })
        .catch((err) => console.log(err));
};
career(limit, offset);

function BTNN() {
    career(limit, offset);
}

function initializeEvent(id) {
    const viewEle = document.getElementById(id);
    if (viewEle) {
        viewEle.addEventListener("click", function () {
            const url = new URL(`${process.env.APIURL}/apply-career`);
            url.searchParams.append("id", id);
            window.open(url.href, "_self");
        });
    }
}

function getPortfolioById() {
    const baseUrl = window.location.href;
    const koopId = baseUrl.split("=")[1];
    if (koopId) getPositionById(koopId);
}
getPortfolioById();

function getPositionById(id) {
    let url = `${process.env.APIURL}/blog/api/open-positions/${id}`;
    const jobTitle = document.getElementById("designation_title");
    const experience = document.getElementById("experience");
    const introduction = document.getElementById("introduction");
    const jobSkill = document.getElementById("job_skill");
    const jobDescription = document.getElementById("job_description");

    console.log({jobTitle})

    fetch(url)
        .then((response) => response.json())
        .then((data_portfolio) => {
            if (data_portfolio.data) {
                const exp = data_portfolio.data.max_experience !== null && data_portfolio.data.max_experience !== "None"
                    ? `${data_portfolio.data.min_experience} - ${data_portfolio.data.max_experience} Year Experience`
                    : `${data_portfolio.data.min_experience} + Year Experience`;

                if (jobTitle) jobTitle.innerHTML = data_portfolio.data.title;
                if (experience) experience.innerHTML = exp;
                if (introduction) introduction.innerHTML = data_portfolio.data.description;

                if (jobSkill) {
                    data_portfolio.data.desired_skillsets.forEach((el) => {
                        const li = document.createElement("li");
                        li.classList.add("listTpyo");
                        li.innerHTML = el;
                        jobSkill.appendChild(li);
                    });
                }

                if (jobDescription) {
                    data_portfolio.data.roles_responsibilities.forEach((el) => {
                        const li = document.createElement("li");
                        li.classList.add("listTpyo");
                        li.innerHTML = el;
                        jobDescription.appendChild(li);
                    });
                }
            }
        })
        .catch((err) => console.log({err}));
}

window.getData = getData;
window.career = career;
window.BTNN = BTNN;
window.initializeEvent = initializeEvent;
window.getPortfolioById = getPortfolioById;
window.getPositionById = getPositionById;